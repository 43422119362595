import React from 'react'
import PropTypes from 'prop-types'

function StatusIcon({ className, fill, width, height }) {
  return (
    <svg fill={fill} className={className} width={width} height={height} version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
      <path d="m451.26 100.03c21.984 0.5625 41.016 15.422 46.922 36.609l206.16 742.22 98.203-294.66c6.8438-20.438 25.922-34.219 47.438-34.219h200.02c27.609 0 50.016 22.406 50.016 50.016s-22.406 50.016-50.016 50.016h-163.97l-138.61 415.78c-6.9375 20.859-26.719 34.734-48.703 34.172s-41.016-15.422-46.875-36.609l-206.21-742.22-98.203 294.66c-6.7969 20.438-25.922 34.219-47.438 34.219h-150c-27.609 0-50.016-22.406-50.016-50.016s22.406-50.016 50.016-50.016h113.95l138.61-415.78c6.9375-20.859 26.719-34.734 48.703-34.172z" fill-rule="evenodd"/>
    </svg>
  )
}

StatusIcon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
}

StatusIcon.defaultProps = {
  className: undefined,
  fill: '#000000',
  height: '2em',
  width: '2em'
}

export default StatusIcon